import React from "react";
import type Slider from "react-slick";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const NavigationButtons = React.memo(({ onPrev, onNext }: { onPrev: () => void; onNext: () => void }) => (
  <div className="gap-2 flex">
    <Button icon={<LeftOutlined style={{ color: "white" }} />} className="rounded-full bg-gray-500" onClick={onPrev} />
    <Button icon={<RightOutlined style={{ color: "white" }} />} className="rounded-full bg-gray-500" onClick={onNext} />
  </div>
));
NavigationButtons.displayName = "NavigationButtons";
const customPaging = (i: number, activeSlide: number) => (
  <div className={`h-1 w-[18px] rounded-md mx-1 cursor-pointer  ${i === activeSlide ? "bg-primary" : "bg-gray-300"}`} />
);

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
  <button
    {...props}
    className={`absolute left-0 top-1/2 z-10 flex h-8 w-8 -translate-x-full -translate-y-1/2 items-center justify-center rounded-full bg-gray-500/80 transition hover:bg-gray-600 ${
      currentSlide === 0 ? "opacity-50" : ""
    }`}
    aria-hidden="true"
    aria-disabled={currentSlide === 0}
    type="button"
  >
    <LeftOutlined className="h-5 w-5 text-white" />
  </button>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
  <button
    {...props}
    className={`absolute right-0 top-1/2 z-10 flex h-8 w-8 translate-x-full -translate-y-1/2 items-center justify-center rounded-full bg-gray-500/80 transition hover:bg-gray-600 ${
      currentSlide === slideCount - 1 ? "opacity-50" : ""
    }`}
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1}
    type="button"
  >
    <RightOutlined className="h-5 w-5 text-white" />
  </button>
);
export const createSliderSettings = (
  _: React.RefObject<Slider>,
  activeSlide: number,
  setActiveSlide: React.Dispatch<React.SetStateAction<number>>,
  slidesToShow = 1,
  hasPrevNextBtn = false,
  isInfinite = true,
) => ({
  dots: !hasPrevNextBtn,
  infinite: isInfinite,
  speed: 500,
  slidesToShow,
  slidesToScroll: 1,
  beforeChange: (_current: number, next: number) => setActiveSlide(next),
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  appendDots: (dots: React.ReactNode[]) => {
    const maxDots = 12;
    const limitedDots = React.Children.toArray(dots).slice(0, maxDots);

    return (
      <>
        <div className="flex items-center justify-center">
          {/* <NavigationButtons
            onPrev={() => sliderRef.current?.slickPrev()}
            onNext={() => sliderRef.current?.slickNext()}
          /> */}
          <ul className="flex gap-1 m-0 p-0 flex-grow justify-center mt-6">
            {dots.length <= maxDots ? dots : limitedDots}
          </ul>
        </div>
      </>
    );
  },
  customPaging: (i: number) => customPaging(i, activeSlide),
  prevArrow: hasPrevNextBtn ? <SlickArrowLeft /> : undefined,
  nextArrow: hasPrevNextBtn ? <SlickArrowRight /> : undefined,
});
