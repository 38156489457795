import { BlogAuthor } from "@/models/news";
import { Avatar } from "antd/lib";
import { FaIcon } from "../faIcon";
import { useMemo } from "react";
import { IMAGE_CDN } from "@/constant/apiPath";
import Link from "next/link";
import { UserPlatformEnum } from "@/context/AuthContext";
import klardaLogo from "@/asset/images/klarda-logo.png";

interface Props {
  data?: BlogAuthor;
}

export default function AuthorCard({ data }: Props) {
  const authorAvatar = useMemo(() => {
    return data?.user_id?.profile_information?.avatar
      ? `${IMAGE_CDN}${data?.user_id?.profile_information?.avatar}`
      : klardaLogo.src;
  }, [data?.user_id?.profile_information?.avatar]);

  const handleLinkSocial = (type: UserPlatformEnum) => {
    if (!data?.user_id?.user_platform?.length) return;

    const platform = data?.user_id?.user_platform.find(p => p.platform === type);
    if (!platform?.data || !platform.is_visible) return;

    window.open(platform.platform || "", "_blank");
  };

  return (
    <div className="flex flex-col gap-5 items-center">
      <Link href={`/tac-gia/${data?.user_id?.id}`}>
        <div className="rounded-full relative group">
          {/* Outer glow effect */}
          <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-600 to-purple-600 rounded-full opacity-30 group-hover:opacity-50 blur transition duration-200"></div>

          {/* Avatar container */}
          <div className="relative">
            <Avatar
              size={144}
              src={authorAvatar}
              alt="user-avt"
              className="shadow-lg hover:shadow-xl transition-shadow duration-200 size-[110px] md:size-[144px]"
              style={{
                objectFit: "cover",
                objectPosition: "center",
                imageRendering: "auto",
                backgroundColor: "#fff",
              }}
            />
          </div>
        </div>
      </Link>
      <div className="flex flex-col gap-1">
        <Link href={`/tac-gia/${data?.user_id?.id}`}>
          <p className="font-bold text-lg md:text-2xl text-gray-900">
            {data?.user_id?.profile_information?.display_name || "Ẩn danh"}
          </p>
        </Link>

        <p className="font-medium text-base text-gray-500">{`${data?.no_posts} bài viết`}</p>
      </div>

      <div className="flex gap-4">
        {data?.user_id?.user_platform?.some(p => p.platform === UserPlatformEnum.Facebook && p.is_visible) && (
          <FaIcon
            name="facebook"
            className="size-5 text-[#1877F2] cursor-pointer"
            onClick={() => handleLinkSocial(UserPlatformEnum.Facebook)}
          />
        )}
        {data?.user_id?.user_platform?.some(p => p.platform === UserPlatformEnum.Twitter && p.is_visible) && (
          <FaIcon
            name="twitter"
            className="size-5 cursor-pointer"
            onClick={() => handleLinkSocial(UserPlatformEnum.Twitter)}
          />
        )}
        {data?.user_id?.user_platform?.some(p => p.platform === UserPlatformEnum.Github && p.is_visible) && (
          <FaIcon
            name="github"
            className="size-5 cursor-pointer"
            onClick={() => handleLinkSocial(UserPlatformEnum.Github)}
          />
        )}
      </div>
    </div>
  );
}

