"use client";

import AuthorCard from "@/app/components/author/AuthorCard";
import LinkToKlardaApp from "@/app/components/LinkToKlardaApp";
import MyCard from "@/app/components/MyCard";
import { createSliderSettings } from "@/app/components/slider/SliderSettings";
import { CLOUDFRONT_URL } from "@/constant/apiPath";
import useAxiosQuery from "@/hooks/useMyQuery";
import type { BlogAuthor, NewsResponseCDN } from "@/models/news";
import { Skeleton } from "antd/lib";
import dynamic from "next/dynamic";
import { Suspense, useMemo, useRef, useState } from "react";
import Slider from "react-slick";

const MarketInsight = dynamic(() => import("@/app/components/home/MarketInsight"), { ssr: false });
const MainNews = dynamic(() => import("@/app/components/news/MainNews"), { ssr: false });
const MarketInsightCard = dynamic(() => import("@/app/components/MarketInsightCard"), { ssr: false });
const KlardaReport = dynamic(() => import("@/app/components/home/KlardaReport"), { ssr: false });
const LearnAboutCrypto = dynamic(() => import("@/app/components/home/LearnAboutCrypto"), { ssr: false });

export default function Home() {
  const mainSliderRef = useRef<Slider | null>(null);
  const [activeMainSlide, setActiveMainSlide] = useState(0);

  const mainSliderSettings = useMemo(
    () => createSliderSettings(mainSliderRef, activeMainSlide, setActiveMainSlide, 4, true),
    [activeMainSlide],
  );

  const { data: latestMoreData, isLoading: isLoadingLatestMore } = useAxiosQuery<NewsResponseCDN>({
    url: `${CLOUDFRONT_URL}/v1/market-insight/news/vi/news/latest`,
    method: "get",
  });

  const { data: reviewData, isLoading: isLoadingReview } = useAxiosQuery<NewsResponseCDN>({
    url: `${CLOUDFRONT_URL}/v1/market-insight/news/vi/review/project`,
    method: "get",
  });

  const { data: authorData } = useAxiosQuery<{ data: BlogAuthor[] }>({
    url: `/v1/blog/vi/author`,
    method: "get",
  });

  return (
    <>
      <main className="min-h-screen p-0 md:p-2">
        <div className="flex flex-col gap-4">
          {/* latest news */}
          <MyCard
            titleLevel={1}
            title="TIN TỨC MỚI NHẤT"
            extra={<LinkToKlardaApp />}
            styles={{ body: { paddingBottom: "0px !important" } }}
          >
            {latestMoreData?.news && latestMoreData?.news?.length > 0 && (
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
                <div className="col-span-1 lg:col-span-4">
                  <MarketInsightCard
                    apiUrl={`${CLOUDFRONT_URL}/v1/market-insight/news/vi/news/latest`}
                    isShowImageFull
                    redirectToNewsPage
                  />
                </div>
                <div className="col-span-1 lg:col-span-5">
                  <Suspense fallback={<Skeleton active />}>
                    <MainNews
                      // imageClassName="max-h-[400px]"
                      news={latestMoreData?.news?.[0]}
                      isLoading={isLoadingLatestMore}
                      isLargeTitle
                    />
                  </Suspense>
                </div>
                <div className="col-span-1 lg:col-span-3">
                  {latestMoreData?.news?.slice(1, 3).map((news, i) => (
                    <MainNews
                      imageClassName="max-h-[165px]"
                      key={news.id ?? "" + i}
                      news={news}
                      isHideDesc
                      isLoading={isLoadingLatestMore}
                    />
                  ))}
                </div>
              </div>
            )}
          </MyCard>

          <MyCard
            titleLevel={2}
            title="KLARDA REVIEW"
            extra={<LinkToKlardaApp />}
            styles={{ body: { paddingBottom: "0px !important" } }}
          >
            <div className="px-6 md:px-2">
              <Slider {...mainSliderSettings} ref={mainSliderRef} className="p-2">
                {reviewData?.news?.map((news, i) => (
                  <MainNews
                    cardClassName="px-3 mb-2"
                    // imageClassName="max-h-[156px]"
                    key={news.id ?? "" + i}
                    news={news}
                    isLoading={isLoadingReview}
                  />
                ))}
              </Slider>
              {!isLoadingReview && (
                <LinkToKlardaApp isOnlyText label="Xem thêm review khác >" url="/review" className="ml-5" />
              )}
            </div>
          </MyCard>

          <MyCard
            titleLevel={2}
            title="INSIGHT"
            extra={<LinkToKlardaApp />}
            styles={{ body: { paddingBottom: "0px !important" } }}
          >
            <MarketInsight />
          </MyCard>

          <MyCard titleLevel={2} title="MARKET REPORTS" extra={<LinkToKlardaApp />}>
            <KlardaReport />
          </MyCard>

          <MyCard titleLevel={2} title="HỌC ĐẦU TƯ CRYPTO" extra={<LinkToKlardaApp />}>
            <LearnAboutCrypto />
          </MyCard>

          {/* author */}
          <Suspense fallback={<Skeleton active />}>
            <div className="py-0 px-0 md:py-4 md:px-4">
              <h2 className="text-center text-2xl md:text-4xl font-extrabold text-gray-900">Tác giả nổi bật</h2>
              <div className="grid grid-cols-2 md:grid-cols-12 gap-6 md:gap-12 mt-10 mb-4">
                {authorData?.data?.slice(0, 8)?.map(author => (
                  <div className="col-span-1 md:col-span-3" key={author?.user_id?.profile_information?.display_name}>
                    <AuthorCard data={author} />
                  </div>
                ))}
              </div>
              <LinkToKlardaApp isOnlyText label="Xem toàn bộ tác giả >" url="/tac-gia" className="ml-6 md:ml-12" />
            </div>
          </Suspense>
        </div>
      </main>
    </>
  );
}
